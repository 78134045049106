import {getAuthorizationToken} from '../../../services/utils';
import {apiMap} from '@autopay.io/common/lib/util';
import {MultipleBookingPayload} from '../types';

export interface BookingJobLoading {
    type: 'LOADING';
}

export interface NoCurrentJob {
    type: 'NO_JOB';
}

export type BookingJobState = BookingJobResponseSuccess | BookingJobResponseFailure | BookingJobLoading | NoCurrentJob | BookingJobResponseSuccessCancelled;

export const getBookingJob = (tenantId: string, permitDefinitionId: string): Promise<BookingJobResponse> => {
    return getAuthorizationToken().then((token): Promise<BookingJobResponse> => {
        if (token) {
            const url = `${apiMap.bookingClient}/client/booking/jobs?tenantId=${tenantId}&permitDefinitionId=${permitDefinitionId}`;
            return fetch(url, {
                headers: {
                    'Authorization': 'Bearer ' + token,
                    'content-type': 'application/json',
                },
                method: 'GET',
            }).then((response: Response) => {
                if (response.ok) {
                    return response.json();
                } else if (response.status === 404) {
                    return null;
                } else {
                    return Promise.reject(new Error(`Failed to get booking job. Error code ${response.status} for url ${response.url}`));
                }
            }).then((json: BookingJob | null): BookingJobResponseSuccess => {
                if (!json) {
                    return {
                        type: 'NO_JOB',
                    };
                } else {
                    return {
                        type: 'DATA',
                        data: json,
                    };
                }
            }).catch((err): BookingJobResponseFailure => {
                return {type: 'ERROR'};
            });
        } else {
            return Promise.resolve({type: 'ERROR'} as BookingJobResponseFailure);
        }
    });
};

export const cancelBookingJob = (tenantId: string, permitDefinitionId: string): Promise<BookingJobCancelledResponse> => {
    return getAuthorizationToken().then((token): Promise<BookingJobCancelledResponse> => {
        if (token) {
            return fetch(`${apiMap.bookingClient}/client/booking/jobs/cancel`, {
                headers: {
                    'Authorization': 'Bearer ' + token,
                    'content-type': 'application/json',
                },
                method: 'POST',
                body: JSON.stringify({tenantId, permitDefinitionId}),
            }).then((response: Response): BookingJobResponseSuccessCancelled | Promise<never> => {
                if (response.ok) {
                    return {type: 'CANCELLED'};
                } else {
                    return Promise.reject(new Error(`Failed to cancel booking job. Error code ${response.status} for url ${response.url}`));
                }
            }).catch((err): BookingJobResponseFailure => {
                return {type: 'ERROR'};
            });
        } else {
            return Promise.resolve({type: 'ERROR'} as BookingJobResponseFailure);
        }
    });
};

export const removeBookingJob = (tenantId: string, permitDefinitionId: string): Promise<BookingJobRemovedResponse> => {
    return getAuthorizationToken().then((token): Promise<BookingJobRemovedResponse> => {
        if (token) {
            return fetch(`${apiMap.bookingClient}/client/booking/jobs`, {
                headers: {
                    'Authorization': 'Bearer ' + token,
                    'content-type': 'application/json',
                },
                method: 'DELETE',
                body: JSON.stringify({tenantId, permitDefinitionId}),
            }).then((response: Response): BookingJobRemovedResponse | Promise<never> => {
                if (response.ok) {
                    return {type: 'REMOVED'};
                } else {
                    return Promise.reject(new Error(`Failed to remove booking job. Error code ${response.status} for url ${response.url}`));
                }
            }).catch((err): BookingJobResponseFailure => {
                return {type: 'ERROR'};
            });
        } else {
            return Promise.resolve({type: 'ERROR'} as BookingJobResponseFailure);
        }
    });
};

export const addBookingForMultipleUsers = (data: MultipleBookingPayload): Promise<AddMultipleBookingResponse> => {
    return getAuthorizationToken().then((token): Promise<AddMultipleBookingResponse> => {
        if (token) {
            return fetch(`${apiMap.bookingClient}/client/booking/jobs`, {
                headers: {
                    'Authorization': 'Bearer ' + token,
                    'content-type': 'application/json',
                },
                method: 'POST',
                body: JSON.stringify(data),
            }).then((response: Response): AddMultipleBookingResponseSuccess | Promise<never> => {
                if (response.ok) {
                    return {type: 'SUCCESS_ADDING_BOOKING'};
                } else {
                    return Promise.reject(new Error(`Failed to create permit. Errorcode ${response.status} for url ${response.url}`));
                }
            }).catch((err): AddBookingError => {
                return {type: 'ERROR_ADDING_BOOKING'};
            });
        } else {
            return Promise.resolve({type: 'ERROR_ADDING_BOOKING'} as AddBookingError);
        }
    });
};

export type BookingJobStatus = 'IN_PROGRESS' | 'SUCCESS' | 'CANCELLED';

export interface BookingJob {
    status: BookingJobStatus;
    progress: number;
    errorItems: string[];
    completed: number;
    cancelled: number;
    total: number;
}

export interface BookingJobResponseSuccessData {
    type: 'DATA';
    data: BookingJob;
}

export interface BookingJobResponseSuccessNoJob {
    type: 'NO_JOB';
}

export interface BookingJobResponseFailure {
    type: 'ERROR';
}

export interface BookingJobResponseSuccessCancelled {
    type: 'CANCELLED';
}

export interface BookingJobResponseSuccessRemoving {
    type: 'REMOVED';
}

export interface AddMultipleBookingResponseSuccess {
    type: 'SUCCESS_ADDING_BOOKING';
}

export interface AddBookingError {
    type: 'ERROR_ADDING_BOOKING';
}

export type AddMultipleBookingResponse = AddMultipleBookingResponseSuccess | AddBookingError;

export type BookingJobCancelledResponse = BookingJobResponseSuccessCancelled | BookingJobResponseFailure;

export type BookingJobRemovedResponse = BookingJobResponseSuccessRemoving | BookingJobResponseFailure;

export type BookingJobResponseSuccess = BookingJobResponseSuccessData | BookingJobResponseSuccessNoJob;

export type BookingJobResponse = BookingJobResponseSuccess | BookingJobResponseFailure;
