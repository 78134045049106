import * as React from 'react';
import {AioPage, Button, ButtonContainer, FormGroup, Message, Spacer} from '@autopay.io/style';
import {Formik, FormikHelpers} from 'formik';
import * as Yup from 'yup';
import {FormikButton, FormikForm, FormikInputField} from '@autopay.io/style/lib/formik';
import {firebaseAuthService} from '@autopay.io/common/lib/auth';
import {ForgotPasswordFirebaseResponse, ForgotPasswordForm} from '../types';
import {Link} from 'react-router-dom';
import {routes} from '../../../routes';

const validationSchema = Yup.object().shape({
    email: Yup.string()
        .email('Valid email required'),
});

export const ForgotPassword = () => {

    const [firebaseResponse, setFirebaseResponse] = React.useState<ForgotPasswordFirebaseResponse | null>(null);

    const initialValues = {email: ''};

    const renderFormik = () => {
        return (
            <>
                <FormikForm>
                    <FormGroup>
                        <FormikInputField
                            name="email"
                            labelText="E-mail"
                        />
                    </FormGroup>
                    <Spacer size="md" />
                    <ButtonContainer alignment="left">
                        <FormikButton type="submit">
                            Send e-mail
                        </FormikButton>
                    </ButtonContainer>
                </FormikForm>
            </>
        );
    };

    return (
        <AioPage type="panel-page">
            <h1>Autopay Booking</h1>
            <Spacer size="md" />
            <h2>Forgot your password?</h2>
            <Spacer size="xs" />
            <p>Please enter your e-mail address to start the process of
                resetting your password
            </p>
            <Spacer size="md" />
            {firebaseResponse && (
                <>
                    <Message type={firebaseResponse.type} message={firebaseResponse.message} />
                    <Spacer size="md" />
                </>
            )}
            <Formik
                key="forgot-formik"
                onSubmit={submit}
                initialValues={initialValues}
                validationSchema={validationSchema}
                render={renderFormik}
            />
            <Spacer size="md" />
            <Link to={routes.ROOT}>
                <Button color="link">Back to login</Button>
            </Link>
        </AioPage>
    );

    function submit(form: ForgotPasswordForm, formik: FormikHelpers<ForgotPasswordForm>) {
        if (firebaseAuthService.firebaseInstance.auth) {
            formik.setSubmitting(true);
            firebaseAuthService.firebaseInstance.auth().sendPasswordResetEmail(form.email)
                .then(() => {
                    setFirebaseResponse({
                        type: 'success',
                        message: 'We have sent an e-mail with a link to reset your password immediately.',
                    });
                })
                .catch((error) => {
                    if (error.code === 'auth/user-not-found' || error.code === 'auth/user-not-found') {
                        setFirebaseResponse({type: 'error', message: 'We could not find a user with the entered e-mail.'});
                    } else {
                        setFirebaseResponse({
                            type: 'error',
                            message: 'Something went wrong when trying to send the password reset e-mail. Please try again.',
                        });
                    }
                })
                .finally(() => formik.setSubmitting(false));
        }
    }
};
