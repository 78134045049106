import {firebaseAuthService} from '@autopay.io/common/lib/auth';
import {AuthError} from './types';

export const loginWithEmailAndPassword = (email: string, password: string) => {
    if (firebaseAuthService.currentUser) {
        return firebaseAuthService.firebaseInstance.auth!().signOut()
            .then(() => signIn(email, password))
            .catch(() => {
                console.log('Failed to commit .signOut() from firebase before .signInWithEmailAndPassword()');
                return signIn(email, password);
            });
    } else {
        return signIn(email, password);
    }
};

const signIn = (email: string, password: string) => {
    firebaseAuthService.unsubscribeToAuthChanges();
    return firebaseAuthService.firebaseInstance.auth!().signInWithEmailAndPassword(email, password)
        .then((resp) => resp)
        .catch((e: AuthError) => {
            firebaseAuthService.subscribeToAuthChanges();
            throw e;
        });
};

export const getLoginErrorMessage = (error: AuthError) => {
    switch (error.code) {
        case 'auth/invalid-email':
            return 'Invalid user format';
        case 'auth/user-disabled':
            return 'User already exists but incomplete';
        default:
            return 'Invalid credentials';
    }
};

export const passwordConfirmationErrorMessage = (error: AuthError) => {
    switch (error.code) {
        case 'auth/weak-password':
            return 'Password is not strong enough';
        case 'auth/expired-action-code':
            return 'The password reset was requested more than 1 hour ago';
        case 'auth/invalid-action-code':
            return 'Password reset link is already used';
        default:
            return 'Error resetting password';
    }
};
