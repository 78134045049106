import * as React from 'react';
import {useFormikContext} from 'formik';
import {BookingAvailabilityState, CreateOrEditBookingForm, Permit} from '../types';
import {Button, DatePicker, FormGroup, IconWithTooltip, Label, Spacer} from '@autopay.io/style';
import moment = require('moment');

interface BookingsStartsSelectorProps {
    permit: Permit;
    bookingAvailability: BookingAvailabilityState;
    onDurationPresetEdit: (durations: number[]) => void;
    fetchAvailability: (date: string, clientId: string) => void;
}

export const BookingStartsSelector = (props: BookingsStartsSelectorProps) => {
    const formik = useFormikContext<CreateOrEditBookingForm>();
    const isDateInPast = moment(formik.values.validFrom).isBefore(moment(), 'minute');

    // This is to show dates in the past, and still disable them
    const minDate = isDateInPast ? formik.values.validFrom : 'today';
    const disable = [{
        from: moment(formik.initialValues.validFrom).add(5, 'minute').toDate(),
        to: moment().subtract(1, 'day').startOf('day').toDate(),
    }];

    const getTooltipText = (limit: number) => {
        return (
            <>
                <p>There is a limitation of {limit} concurrent bookings for this permit.</p>
                <b>Entry type bookings are disabled.</b>
            </>
        );
    };

    return (
        <FormGroup>
            <div className="label-with-tooltip">
                <Label>Booking starts:</Label>
                {props.bookingAvailability && props.bookingAvailability !== 'ERROR' && props.bookingAvailability.limitAmount &&
                    (
                        <IconWithTooltip
                            icon="tooltip"
                            text={getTooltipText(props.bookingAvailability.limitAmount)}
                            position="right"
                        />
                    )
                }
            </div>
            <div className="booking-starts-buttons-container">
                <Button
                    type="button"
                    disabled={formik.values.bookingId !== undefined && formik.values.type === 'ENTRY'}
                    color={formik.values.type === 'FIXED' ? 'primary-green' : 'secondary'}
                    onClick={() => formik.setFieldValue('type', 'FIXED')}
                >
                    Selected date
                </Button>
                <Spacer size="xs" />
                <Button
                    type="button"
                    disabled={!!props.permit.accessLimit || (formik.values.bookingId !== undefined && formik.values.type === 'FIXED')}
                    color={formik.values.type === 'ENTRY' ? 'primary-green' : 'secondary'}
                    onClick={() => formik.setFieldValue('type', 'ENTRY')}
                >
                    On entry
                </Button>
            </div>
            {formik.values.type === 'FIXED' &&
                (
                    <>
                        <Spacer size="sm" />
                        <Label htmlFor="validFrom">Valid from:</Label>
                        <DatePicker
                            selectedDate={formik.values.validFrom}
                            enableTime={true}
                            onDateChange={(e) => {
                                props.fetchAvailability(moment(e).format(), props.permit.clientId);
                                const newDate = moment(e).isBefore(moment(), 'day') ?
                                    moment().set({hour: e.getHours(), minute: e.getMinutes()}).toDate() : e;
                                formik.setFieldValue('validFrom', newDate);
                            }}
                            options={{
                                minDate,
                                maxDate: moment().add(100, 'year').toDate(),
                                dateFormat: 'd-m-Y H:i',
                                disable,
                                onOpen: [(selectedDates, dateStr, instance) => {
                                    instance.jumpToDate(moment().toDate());
                                }],
                            }}
                        />
                    </>
                )
            }
        </FormGroup>
    );
};
