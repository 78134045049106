import { Permit, Time } from './scenes/bookings/types';
import * as moment from 'moment';

export const DURATION_ONE_DAY = 86400;
export const DURATION_TWO_DAYS = 172800;
export const DURATION_THREE_DAYS = 259200;
export const SECONDS_IN_HOUR = 3600;

export function convertSecondsToTime(seconds: number): Time {
    const days = Math.floor(seconds / (24 * 3600));
    const hours = (seconds % (24 * 3600)) / 3600;
    return { days, hours };
}

export function convertTimeToSeconds(time: Time) {
    const totalHours = time.days * 24 + time.hours;
    return totalHours * 60 * 60;
}

export function getPermitIdentifier(permit: Permit) {
    return `${permit.id}_${permit.tenantId}`;
}

export function formatDate(d?: string) {
    return d ? moment(d).format('DD.MM.YY') + ' ' + moment(d).format('HH:mm') : '';
}

export function formatPayLoadDate(date: Date) {
    return moment(date).format('YYYY-MM-DDTHH:mm:ssZZ');
}
