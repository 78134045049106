import * as React from 'react';
import * as Yup from 'yup';
import {Formik, FormikHelpers} from 'formik';
import {AioPage, Button, FormGroup, Message, Spacer} from '@autopay.io/style';
import {LoginAuthResponse, LoginForm} from '../types';
import {routes} from '../../../routes';
import {getLoginErrorMessage, loginWithEmailAndPassword} from '../auth';
import {FormikButton, FormikForm, FormikInputField} from '@autopay.io/style/lib/formik';
import {browserHistory} from '../../../app';

const validationSchema = Yup.object().shape({
    email: Yup.string()
        .email('Valid email required'),
    password: Yup.string()
        .required(() => 'Password required'),
});

export const Login = () => {
    const [authResponse, setAuthResponse] = React.useState<LoginAuthResponse | null>(null);

    const submit = (form: LoginForm, formik: FormikHelpers<LoginForm>) => {
        formik.setSubmitting(true);

        loginWithEmailAndPassword(form.email, form.password).then(() => window.location.href = routes.BOOKINGS)
            .catch((error) => {
                formik.setSubmitting(false);
                setAuthResponse({type: 'error', message: getLoginErrorMessage(error)});
            });
    };

    return (
        <AioPage type="panel-page">
            <h1>Autopay Booking</h1>
            <Spacer size="md" />
            <h2>Log in</h2>
            <Spacer size="xs" />
            {authResponse && (
                <>
                    <Message type={authResponse.type} message={authResponse.message} />
                    <Spacer size="md" />
                </>
            )}
            <Formik
                key="formik"
                onSubmit={submit}
                initialValues={{email: '', password: ''}}
                validationSchema={validationSchema}
            >
                <FormikForm>
                    <FormGroup>
                        <FormikInputField
                            name="email"
                            labelText="E-mail"
                        />
                    </FormGroup>
                    <Spacer size="md" />
                    <FormGroup>
                        <FormikInputField
                            type="password"
                            name="password"
                            labelText="Password"
                        />
                    </FormGroup>

                    <Spacer size="md" />
                    <FormikButton type="submit">Log in</FormikButton>
                    <Spacer size="md" />
                    <Button color="link" onClick={() => browserHistory.push(routes.RESET_PASSWORD)}>
                        Forgot your password?
                    </Button>
                </FormikForm>
            </Formik>
        </AioPage>
    );
};
