import * as  React from 'react';
import {useState} from 'react';
import {Button, ButtonCell, IconWithLabel, Popup, Spacer, Table, TableBody, TableCell, TableHeader, TableRow, useToasts} from '@autopay.io/style';
import {Booking} from '../../types';
import {deleteBooking} from '../../../../services/http';
import * as moment from 'moment';

interface BookingsTableProps {
    bookings: Booking[];
    clientId: string;
    getBookings: () => void;
    fetchAvailability: (date: string, clientId: string) => void;
    onEditClick: (booking: Booking) => void;
    isSortAscending: boolean;
    setIsSortAscending: () => void;
}

export type SortHeaders = 'createdAt';

const BookingsTable = (props: BookingsTableProps) => {
    const {addToast} = useToasts();

    const [sortedHeaderId] = useState<SortHeaders>('createdAt');

    const [isDeleteInProgress, setIsDeleteInProgress] = useState<boolean>(false);
    const [removeBooking, setRemoveBooking] = useState<Booking | null>(null);

    const columns = [
        {id: 'licensePlateNumber', name: 'License plate'},
        {id: 'validFrom', name: 'Valid from'},
        {id: 'validTo', name: 'Valid to'},
        {id: 'duration', name: 'Duration'},
        {id: 'comment', name: 'Comment'},
        {id: 'createdAt', name: 'Created at', sortFunction: () => props.setIsSortAscending()},
        {id: 'status', name: 'Status'},
        {id: 'Actions', name: ''},
    ];

    function renderDuration(booking: Booking) {
        if (!booking.duration) {
            return '-';
        }

        const seconds = Number(booking.duration);
        const days = Math.floor(seconds / (24 * 3600));
        const hours = Math.floor(seconds % (24 * 3600) / 3600);
        const minutes = Math.floor((seconds / 60) % 60);

        return <span>{days}d {hours}h {minutes > 0 && `${minutes} min`}</span>;
    }

    function renderDate(input?: string) {
        if (!input) {
            return '-';
        }

        const date = moment(input);

        return (
            <span>{date.format('DD.MM.YY')} {date.format('HH:mm')}</span>
        );
    }

    function onRemoveClick(booking: Booking) {
        setRemoveBooking(booking);
    }

    function onRemoveConfirmClick(booking: Booking) {
        setIsDeleteInProgress(true);

        deleteBooking(booking.bookingId, props.clientId).then((res) => {
            if (res.type === 'OK') {
                addToast({type: 'success', body: 'Booking successfully removed.'});
            } else if (res.type === 'ERROR_MESSAGE') {
                addToast({type: 'error', body: res.message});
            } else {
                addToast({type: 'error', body: 'Something went wrong trying to remove the booking. Please try again later.'});
            }

            props.getBookings();
            setRemoveBooking(null);
            setIsDeleteInProgress(false);
            props.fetchAvailability(moment().format().toString(), props.clientId);
        });
    }

    function renderRemoveBookingPopup(booking: Booking) {
        return (
            <Popup
                body={
                    (
                        <>
                            <b>Are you sure you want to remove this booking?</b>
                            <Spacer size="xxs" />
                            <p>This action cannot be undone.</p>
                        </>
                    )
                }
                buttons={[
                    <Button key="btn-close" color="secondary" disabled={isDeleteInProgress} onClick={() => setRemoveBooking(null)} tag="button">Cancel</Button>,
                    <Button key="btn-accept" color="primary" loading={isDeleteInProgress} onClick={() => onRemoveConfirmClick(booking)}>Remove</Button>,
                ]}
                close={() => setRemoveBooking(null)}
                show
                title="Remove booking"
            />
        );
    }

    const renderStatus = (status: string, validWasUsed?: boolean) => {
        if (status === 'NOT_USED') {
            if (validWasUsed) { return <IconWithLabel icon="vehicles_category_green" label="Valid (used)" />; } else { return <IconWithLabel icon="check_green" label="Valid (not used)" />; }
        }
        if (status === 'IN_USE') { return <IconWithLabel icon="vehicles_category_blue" label="In use" />; }
        if (status === 'USED') { return <IconWithLabel icon="vehicles_category_gray" label="Used" />; }
        if (status === 'EXPIRED') { return <IconWithLabel icon="cross_red" label="Expired" />; }
    };

    return (
        <div className="bookings-table">
            <Table sortable={true}>
                <TableHeader headers={columns} sortedHeaderId={sortedHeaderId} sortDirection={props.isSortAscending ? 'asc' : 'desc'} />
                <TableBody>
                    {props.bookings.map((booking, index) => (
                        <TableRow key={booking.bookingId + index} expired={['USED', 'EXPIRED'].includes(booking.status)}>
                            <TableCell title={columns[0].name} content={booking.licensePlateNumber} />
                            <TableCell title={columns[1].name} content={moment(booking.validFrom).isValid() ? renderDate(booking.validFrom) : 'Entry time'} />
                            <TableCell title={columns[2].name} content={renderDate(booking.validTo)} />
                            <TableCell title={columns[3].name} content={renderDuration(booking)} />
                            <TableCell title={columns[4].name} content={booking.comment ?? '-'} />
                            <TableCell title={columns[5].name} content={renderDate(booking.createdAt)} />
                            <TableCell title={columns[6].name} content={renderStatus(booking.status, booking.validWasUsed)} />
                            <ButtonCell>
                                {(booking.status === 'NOT_USED' && !booking.validWasUsed) &&
                                    <Button size="sm" color="secondary" onClick={() => onRemoveClick(booking)}>Remove</Button>
                                }
                                {!(['USED', 'EXPIRED'].includes(booking.status)) &&
                                    <Button size="sm" color="secondary" onClick={() => props.onEditClick(booking)}>Edit</Button>
                                }
                            </ButtonCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
            {removeBooking && renderRemoveBookingPopup(removeBooking)}
        </div>
    );
};

export default BookingsTable;
