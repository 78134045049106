import * as React from 'react';
import {BookingAvailabilityState, BookingType, CreateOrEditBookingForm, Permit} from '../../types';
import {createBooking} from '../../../../services/http';
import BookingForm from '../../components/bookingForm';
import moment = require('moment');
import {Moment} from 'moment';

interface CreateBookingProps {
    permit: Permit;
    goBack: (selectedPermit: Permit) => void;
    bookingsAvailability: BookingAvailabilityState;
    fetchAvailability: (date: string, clientId: string) => void;
}

function CreateBooking(props: CreateBookingProps) {
    const [permit, setPermit] = React.useState<Permit>(props.permit);

    const getValidFrom = (): Moment => {
        if (props.bookingsAvailability && props.bookingsAvailability !== 'ERROR' && props.bookingsAvailability.validFrom) {
            return moment(props.bookingsAvailability.validFrom);
        } else {
            return moment().startOf('day');
        }
    };

    const getInitialBookingType = (): BookingType => {
        if (props.bookingsAvailability === null || props.bookingsAvailability === 'ERROR' ||
            (props.bookingsAvailability && props.bookingsAvailability.limitAmount && props.bookingsAvailability.limitAmount > 0)) {
            return 'FIXED';
        } else {
            return 'ENTRY';
        }
    };

    const initialValues: CreateOrEditBookingForm = {
        licensePlateNumber: '',
        comment: '',
        clientId: props.permit.clientId,
        type: getInitialBookingType(),
        validFrom: getValidFrom().toDate(),
        validTo: getValidFrom().add(2879, 'minutes').toDate(),
        endType: props.permit.accessLimit ? 'SELECTED' : undefined,
    };

    function onDurationPresetEdit(durations: number[]) {
        setPermit((prevState) => ({
            ...prevState,
            durations,
        }));
    }

    return (
        <BookingForm
            onDurationPresetEdit={onDurationPresetEdit}
            initialValues={initialValues}
            onSubmit={createBooking}
            goBack={() => props.goBack(permit)}
            permit={permit}
            bookingsAvailability={props.bookingsAvailability}
            fetchAvailability={props.fetchAvailability}
        />
    );
}

export default CreateBooking;
