import './index.less';
import '@autopay.io/style/autopay-io.css';

import 'whatwg-fetch';
import 'core-js/es6/set';
import * as React from 'react';
import {render} from 'react-dom';
import {matchPath, Router as ReactRouter} from 'react-router-dom';
import {createBrowserHistory, Location} from 'history';
import {AppUser} from './types/user';
import {firebaseAuthService, User} from '@autopay.io/common/lib/auth';
import {AppContextProvider} from './context';
import Router from './router';
import Plausible from 'plausible-tracker';
import {getDomain} from '@autopay.io/common/lib/util';
import {getAllStringsFromObject} from '@autopay.io/common/lib/getAllStringsFromObject';
import {routes} from './routes';

// https://plausible.io/docs/integration-guides#npm-package
const plausible = Plausible({domain: `booking.${getDomain()}`});
export const browserHistory = createBrowserHistory();

export const App = () => {
    const [appUser, setAppUser] = React.useState<AppUser>({type: 'LOADING'});

    React.useEffect(() => {
        firebaseAuthService.addAuthListener(toggleUser);
        logPathToPlausible(browserHistory.location);
        browserHistory.listen((location) => logPathToPlausible(location));

        return function cleanup() {
            firebaseAuthService.removeAuthListener(toggleUser);
        };
    });

    const toggleUser = (user: User | null) => {
        if (user) {
            setAppUser({type: 'USER', data: user});
        } else {
            setAppUser({type: 'NO_USER'});
        }
    };

    return (
        <AppContextProvider value={{user: appUser}}>
            <ReactRouter history={browserHistory}>
                <Router />
            </ReactRouter>
        </AppContextProvider>
    );

};

function logPathToPlausible(location: Location) {
    let path = location.pathname;
    const allPaths = getAllStringsFromObject(routes);
    allPaths.map((route) => {
        if (matchPath(path, {path: route, exact: true}) !== null) {
            path = route;
        }
    });
    plausible.trackPageview({url: path});
}

render(<App />, document.getElementById('app'));
