import * as React from 'react';
import {useEffect} from 'react';
import {AioPage, Button, ButtonContainer, FormGroup, Message, Spacer} from '@autopay.io/style';
import {FormikButton, FormikForm, FormikInputField} from '@autopay.io/style/lib/formik';
import {Formik, FormikHelpers} from 'formik';
import {firebaseAuthService} from '@autopay.io/common/lib/auth';
import {SetPasswordAuthResponse, SetPasswordForm} from '../types';
import * as Yup from 'yup';
import {getLoginErrorMessage, loginWithEmailAndPassword, passwordConfirmationErrorMessage} from '../auth';
import {routes} from '../../../routes';
import {browserHistory} from '../../../app';

const validationSchema = Yup.object().shape({
    password: Yup.string()
        .required(() => 'Password required'),
    confirmPassword: Yup.string()
        .required(() => 'Password required'),
});

export const SetPassword = () => {
    const [email, setEmail] = React.useState<string>('');
    const [authResponse, setAuthResponse] = React.useState<SetPasswordAuthResponse | null>(null);
    const [initializeError, setInitializeError] = React.useState<SetPasswordAuthResponse | null>(null);
    const [actionCode, setActionCode] = React.useState<string>('');

    useEffect(() => {
        const params = new URLSearchParams(window.location.search);
        const oobCode = params.get('oobCode')!;
        setActionCode(oobCode);

        firebaseAuthService.addInitializationListener(() => {
            if (firebaseAuthService.firebaseInstance.auth) {
                firebaseAuthService.firebaseInstance.auth().verifyPasswordResetCode(oobCode)
                    .then((responseEmail) => {
                        setEmail(responseEmail);
                    })
                    .catch((error) => {
                        setInitializeError({type: 'error', message: passwordConfirmationErrorMessage(error)});
                    });
            }
        });
    }, []);

    const renderFirebaseError = () => {
        if (initializeError && initializeError.message === 'The password reset was requested more than 1 hour ago') {
            return (
                <>
                    <Message type={initializeError.type} message={initializeError.message} />
                    <Spacer size="md" />
                    <Button color="link" onClick={() => browserHistory.push(routes.RESET_PASSWORD)}>
                        Request a new email with password reset link
                    </Button>

                </>
            );
        } else if (initializeError) {
            return (
                <>
                    <Message type={initializeError.type} message={initializeError.message} />
                    <Spacer size="md" />
                    <Button color="link" onClick={() => browserHistory.push(routes.ROOT)}>Go to login</Button>
                </>
            );
        } else {
            return '';
        }
    };

    const renderSetPasswordForm = () => {
        return (
            <FormikForm>
                <FormGroup>
                    <FormikInputField
                        name="password"
                        type="password"
                        labelText="Password"
                        helperText={'Password must be at least 6 characters long and ' +
                        'contain at least 1 uppercase letter, lowercase letter and 1 number'}
                    />
                    <Spacer size="xs" />
                    <FormikInputField
                        name="confirmPassword"
                        type="password"
                        labelText="Confirm password"
                    />
                </FormGroup>
                <Spacer size="md" />
                <ButtonContainer alignment="left">
                    <FormikButton type="submit">Save</FormikButton>
                </ButtonContainer>
            </FormikForm>
        );
    };

    return (
        <AioPage type="panel-page">
            <h1>Autopay Booking</h1>
            <Spacer size="md" />
            {initializeError ? renderFirebaseError() :
                (
                    <>
                        <h2>Set password</h2>
                        <Spacer size="md" />
                        <h3>For account {email}</h3>
                        <Spacer size="sm" />
                        {authResponse && (
                            <>
                                <Message type={authResponse.type} message={authResponse.message} />
                                <Spacer size="sm" />
                            </>
                        )}
                        <Formik
                            onSubmit={submit}
                            initialValues={{password: '', confirmPassword: ''}}
                            validationSchema={validationSchema}
                        >
                            {renderSetPasswordForm()}
                        </Formik>
                    </>
                )}
        </AioPage>
    );

    function submit(form: SetPasswordForm, formik: FormikHelpers<SetPasswordForm>) {
        formik.setSubmitting(true);

        if (form.password !== form.confirmPassword) {
            setAuthResponse({type: 'error', message: 'Passwords are not the same'});
            formik.setSubmitting(false);
            return;
        }

        if (firebaseAuthService.firebaseInstance.auth && actionCode) {
            firebaseAuthService.firebaseInstance.auth().confirmPasswordReset(actionCode, form.password)
                .then(() => {
                    loginWithEmailAndPassword(email, form.password).then(() => window.location.href = routes.BOOKINGS)
                        .catch((err) => {
                            formik.setSubmitting(false);
                            setAuthResponse({type: 'error', message: getLoginErrorMessage(err)});
                        });
                })
                .catch((error) => {
                    formik.setSubmitting(false);
                    setAuthResponse({type: 'error', message: passwordConfirmationErrorMessage(error)});
                });
        }
    }
};
