import {Booking} from './types';
import * as moment from 'moment';

export const recalculateBookingsStatuses = (bookings: Booking[]): Booking[] => {
    const now = moment();
    bookings.forEach((booking) => {
        if (booking.status === 'USED' && !booking.usableOnce && moment(booking.validTo).isAfter(now)) {
            booking.status = 'NOT_USED';
            booking.validWasUsed = true;
        }
    });

    return bookings;
};
