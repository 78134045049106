import * as React from 'react';
import {Popup, Label, Input, Button, Spacer, FormGroup} from '@autopay.io/style';
import {UpdateDurationsPayload, Permit, Time} from '../types';
import {updateDurations} from '../../../services/http';
import {convertSecondsToTime, convertTimeToSeconds, DURATION_ONE_DAY, DURATION_TWO_DAYS, DURATION_THREE_DAYS} from '../../../utils';

interface SettingsPopupProps {
    onClose: () => void;
    permit: Permit;
    show: boolean;
    onDurationPresetEdit: (durations: number[]) => void;
}

function SettingsPopup(props: SettingsPopupProps) {
    const [isLoading, setIsLoading] = React.useState<boolean>(false);
    const [buttonOneState, setButtonOneState] = React.useState<Time>(convertSecondsToTime(DURATION_ONE_DAY));
    const [buttonTwoState, setButtonTwoState] = React.useState<Time>(convertSecondsToTime(DURATION_TWO_DAYS));
    const [buttonThreeState, setButtonThreeState] = React.useState<Time>(convertSecondsToTime(DURATION_THREE_DAYS));

    React.useEffect(() => {
        resolveInitialButtonValues();
    }, []);

    function resolveInitialButtonValues() {
        const durations = props.permit.durations;
        const durationsLength = durations.length;

        if (durationsLength > 0) {
            setButtonOneState(convertSecondsToTime(durations[0]));
        }

        if (durationsLength > 1) {
            setButtonTwoState(convertSecondsToTime(durations[1]));
        }

        if (durationsLength > 2) {
            setButtonThreeState(convertSecondsToTime(durations[2]));
        }
    }

    function saveDurationPreset() {
        setIsLoading(true);

        const {id, tenantId, operator} = props.permit;
        const payload: UpdateDurationsPayload = {
            clientId: props.permit.clientId,
            tenantId,
            operator,
            permitDefinitionId: id,
            durations: [
                convertTimeToSeconds(buttonOneState),
                convertTimeToSeconds(buttonTwoState),
                convertTimeToSeconds(buttonThreeState),
            ],
        };
        updateDurations(payload).then(() => {
            setIsLoading(false);

            props.onDurationPresetEdit(payload.durations);
            props.onClose();
        });
    }

    function getDaysChangeHandler(stateSetter: React.Dispatch<React.SetStateAction<Time>>) {
        return (event: React.ChangeEvent<HTMLInputElement>) => {
            const parsedValue = parseInt(event.target.value, 10);
            const numericDays = Number.isNaN(parsedValue) ? 0 : parsedValue;

            stateSetter((prevState: Time) => ({
                days: numericDays,
                hours: prevState.hours,
            }));
        };
    }

    function getHoursChangeHandler(stateSetter: React.Dispatch<React.SetStateAction<Time>>) {
        return (event: React.ChangeEvent<HTMLInputElement>) => {
            const parsedValue = parseInt(event.target.value, 10);
            const numericHours = Number.isNaN(parsedValue) ? 0 : parsedValue;

            stateSetter((prevState: Time) => ({
                hours: numericHours,
                days: prevState.days,
            }));
        };
    }

    function renderBody() {
        const buttons = [
            {label: 'Button 1', state: buttonOneState, setState: setButtonOneState},
            {label: 'Button 2', state: buttonTwoState, setState: setButtonTwoState},
            {label: 'Button 3', state: buttonThreeState, setState: setButtonThreeState},
        ];

        return (
            <div className="configure-custom-duration">
                {buttons.map((button) => (
                    <div className="custom-duration-option form-group" key={button.label}>
                        <Label>{button.label}:</Label>
                        <div className="input-container">
                            <FormGroup>
                                <Input
                                    id={button.label + 'days'}
                                    helperText="Days"
                                    value={button.state.days}
                                    onChange={getDaysChangeHandler(button.setState)}
                                    type="number"
                                />
                            </FormGroup>
                            <Spacer size="xs" />
                            <FormGroup>
                                <Input
                                    id={button.label + 'hours'}
                                    helperText="Hours"
                                    value={button.state.hours}
                                    onChange={getHoursChangeHandler(button.setState)}
                                    type="number"
                                />
                            </FormGroup>
                        </div>
                        <Spacer size="xs" />
                    </div>
                ))}
                <Button color="primary" type="button" onClick={() => saveDurationPreset()} loading={isLoading}>Save</Button>
                <Button color="secondary" type="button" onClick={() => props.onClose()} disabled={isLoading}>Cancel</Button>
            </div>
        );
    }

    return (
        <Popup
            show={props.show}
            title="Edit duration presets"
            body={renderBody()}
        />
    );
}

export default SettingsPopup;
