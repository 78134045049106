import * as React from 'react';
import {firebaseAuthService} from '@autopay.io/common/lib/auth';
import {Route, Redirect, RouteComponentProps} from 'react-router';
import {routes} from '../routes';
import {context} from '../context';
import {AioHeaderOptions} from '@autopay.io/style/lib/components/Layout/AioPageTypes';
import {AioPage, Button} from '@autopay.io/style';
import {browserHistory} from '../app';
import FeatureIcon from '@autopay.io/style/lib/components/FeatureIcon/FeatureIcon';

export interface PrivateRouteProps {
    Component: any;
    path: string;
}

const PrivateRoute: React.FC<PrivateRouteProps> = ({Component, path}) => {
    const {user} = React.useContext(context);

    const logout = () => {
        firebaseAuthService.logout();
        browserHistory.push(routes.ROOT);
    };

    function getHeaderOptions(): AioHeaderOptions {
        if (user.type === 'USER') {
            return {
                right: [{
                    element: (
                        <div className="menu-header-right-buttons">
                            {/*<Button onClick={logout} className="menu-header-button" color="link-secondary" disabled={disableSettings}>
                                <FeatureIcon icon="gear" color="blue" />
                                Settings
                            </Button>*/}
                            <Button onClick={logout} className="menu-header-button" color="link-secondary">
                                <FeatureIcon icon="log-out" color="blue" />
                                Log out
                            </Button>
                        </div>
                    ),
                }],
                style: 'orange',
            };
        }
        return {};
    }

    if (user.type === 'LOADING') {
        return null;
    }
    if (user.type === 'USER') {

        const render = (props: RouteComponentProps<{}>) => {
            return (
                <AioPage type="page-with-header" {...{header: getHeaderOptions()}}>
                    <Component user={user.data} routeProps={props} />
                </AioPage>
            );
        };

        return <Route path={path} render={render} />;
    }
    return <Redirect to={{pathname: routes.ROOT, search: `?returnUrl=${encodeURIComponent(window.location.pathname + window.location.search)}`}} />;
};

export default PrivateRoute;
