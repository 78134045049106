import * as React from 'react';
import {useEffect, useState} from 'react';
import {Button, Label, Option, Pagination, SearchBar, Select, Spacer, Spinner, useToasts} from '@autopay.io/style';
import {SearchFilter} from '@autopay.io/style/lib/components/Search/SearchBar/SearchBar';
import {Booking, BookingAvailabilityState, BookingsStatusCount, Permit} from '../../types';
import {fetchBookings} from '../../../../services/http';
import BookingsTable from './BookingsTable';
import * as moment from 'moment';
import {getPermitIdentifier} from '../../../../utils';
import Availability from '../../components/Availability';
import {BookingJobProgressInformation} from '../../components/BookingJobProgressInformation';
import {BookingJobResponse, BookingJobState, cancelBookingJob, getBookingJob, removeBookingJob} from '../../services/bookingJob';
import {Translation} from '@autopay.io/translation';
import {recalculateBookingsStatuses} from '../../utils';

interface BookingListProps {
    permits: Permit[];
    previousPermit?: Permit;
    bookingsAvailability: BookingAvailabilityState;
    fetchAvailability: (date: string, clientId: string) => void;
    onCreateClick: (permit: Permit, bookingsAvailability: BookingAvailabilityState) => void;
    onEditClick: (booking: Booking, bookingsAvailability: BookingAvailabilityState) => void;
    onError: () => void;
}

const emptyPermit = {id: '', tenantId: '', tenantName: '', durations: [], operator: '', name: '', clientId: ''};

const BookingList = (props: BookingListProps) => {
    const maxBookingPerPage = 25;
    const {addToast} = useToasts();

    const [activePermit, setActivePermit] = useState<Permit>(emptyPermit);
    const [preSelectedPermit, setPreSelectedPermit] = useState<Permit | undefined>(undefined);
    const [bookings, setBookings] = useState<Booking[]>([]);
    const [bookingsStatusCount, setBookingsStatusCount] = useState<BookingsStatusCount | null>(null);
    const [filteredBookings, setFilteredBookings] = useState<Booking[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [jobState, setJobState] = useState<BookingJobState>({type: 'LOADING'});

    const [currentPage, setCurrentPage] = useState(1);
    const [totalPageCount, setTotalPageCount] = useState(1);
    const [isSortAscending, setIsSortAscending] = useState(false);

    const validFilter: SearchFilter<Booking> = {
        title: <div className="filter-button">Valid {bookingsStatusCount ? `(${bookingsStatusCount.notUsed})` : <Spinner size="xxs" />}</div>,
        key: 'valid',
        doesItemMatchFilter: (booking: Booking) => booking.status === 'NOT_USED',
    };

    const inUseFilter: SearchFilter<Booking> = {
        title: <div className="filter-button">In use {bookingsStatusCount ? `(${bookingsStatusCount.inUse})` : <Spinner size="xxs" />}</div>,
        key: 'inUse',
        doesItemMatchFilter: (booking: Booking) => booking.status === 'IN_USE',
    };

    useEffect(() => {
        if (props.previousPermit) {
            setPreSelectedPermit(props.previousPermit);
        }
        if (props.permits.length > 0) {
            setActivePermit(props.previousPermit ? props.previousPermit : props.permits[0]);
        }
    }, []);

    useEffect(() => {
        if (activePermit.id) {
            getBookings();
            setCurrentPage(1);
            props.fetchAvailability(moment().format(), activePermit.clientId);
        }
    }, [activePermit]);

    useEffect(() => {
        if (jobState.type === 'DATA' && jobState.data.status === 'IN_PROGRESS') {
            const interval = setInterval(() => {
                getJobState();
            }, 1000);
            return () => clearInterval(interval);
        } else {
            return () => clearInterval(undefined);
        }
    }, [jobState]);

    useEffect(() => {
        let isSubscribed;
        if (activePermit.id) {
            isSubscribed = true;
            getJobState();
        }
        return () => {
            isSubscribed = false;
        };
    }, [activePermit]);

    useEffect(() => {
        if (bookings) {
            const statusCount: BookingsStatusCount = {notUsed: 0, inUse: 0, used: 0, expired: 0};
            bookings.forEach((booking) => {
                if (booking.status === 'NOT_USED') {statusCount.notUsed += 1; }
                if (booking.status === 'IN_USE') {statusCount.inUse += 1; }
                if (booking.status === 'USED') {statusCount.used += 1; }
                if (booking.status === 'EXPIRED') {statusCount.expired += 1; }
            });

            setBookingsStatusCount(statusCount);
        }
    }, [bookings]);

    function getBookings() {
        setBookingsStatusCount(null);
        setIsLoading(true);

        fetchBookings(activePermit.id, activePermit.tenantId, activePermit.operator, activePermit.clientId)
            .then((res) => {
            if (res.type === 'SUCCESS') {
                const mappedBookings = recalculateBookingsStatuses(res.data);
                setBookings(mappedBookings);
                const count = Math.ceil(res.data.length / maxBookingPerPage);
                setTotalPageCount(count);
            } else {
                props.onError();
            }

            setIsLoading(false);
        });
    }

    function onPermitChange(event: React.ChangeEvent<HTMLSelectElement>) {
        setPreSelectedPermit(undefined);
        const newPermit = props.permits.find((permit) => getPermitIdentifier(permit) === event.target.value);

        if (newPermit) {
            setBookings([]);
            setActivePermit({
                id: newPermit.id,
                clientId: newPermit.clientId,
                tenantId: newPermit.tenantId,
                tenantName: newPermit.tenantName,
                operator: newPermit.operator,
                name: newPermit.name,
                durations: newPermit.durations,
                accessLimit: newPermit.accessLimit,
            });
        }
    }

    function getSearchFilter(searchWord: string, booking: Booking): boolean {
        return booking.licensePlateNumber.toLocaleLowerCase().includes(searchWord.toLocaleLowerCase());
    }

    function getDefaultFilters(): Array<SearchFilter<Booking>> {
        return [validFilter, inUseFilter];
    }

    function getFilters(): Array<SearchFilter<Booking>> {
        const filterButtons: Array<SearchFilter<Booking>> = getDefaultFilters();

        filterButtons.push(
            {
                title: <div className="filter-button">Used {bookingsStatusCount ? `(${bookingsStatusCount.used})` : <Spinner size="xxs" />}</div>,
                key: 'used',
                doesItemMatchFilter: (booking: Booking) => booking.status === 'USED',
            },
        );

        filterButtons.push(
            {
                title: <div className="filter-button">Expired {bookingsStatusCount ? `(${bookingsStatusCount.expired})` : <Spinner size="xxs" />}</div>,
                key: 'expired',
                doesItemMatchFilter: (booking: Booking) => booking.status === 'EXPIRED',
            },
        );

        return filterButtons;
    }

    const permitList: Option[] = props.permits.map((permit) => ({
        label: permit.name + ` (${permit.tenantName})`,
        key: permit.id + '-' + permit.tenantId,
        value: getPermitIdentifier(permit),
    }));

    const getSelectedPermit = (): string | number | undefined => {
        const selected: Option | undefined = permitList.find((permit) => {
            return preSelectedPermit ? permit.value === getPermitIdentifier(preSelectedPermit) : undefined;
        });
        return selected ? selected.value : undefined;
    };

    const onEdit = (booking: Booking) => {
        props.onEditClick(booking, props.bookingsAvailability);
    };

    const refresh = () => props.fetchAvailability(moment().format(), activePermit.clientId);

    const getJobState = () => {
        getBookingJob(activePermit.tenantId, activePermit.id)
            .then((res: BookingJobResponse) => {
                setJobState((old) => {
                    if (old.type === 'DATA' && old.data.progress < 99.9 && res.type === 'DATA' && res.data.progress >= 99.9) {
                        getBookings();
                    }

                    return res;
                });
            });
    };

    const cancelJob = () => {
        cancelBookingJob(activePermit.tenantId, activePermit.id)
            .then(() => {
                setJobState({type: 'CANCELLED'});
                getJobState();
                getBookings();
            }).catch(() => {
            addToast({
                type: 'error',
                body: Translation.messages().common.error.something_went_wrong,
                autoDismiss: false,
            });
        });
    };

    const removeJob = () => {
        removeBookingJob(activePermit.tenantId, activePermit.id)
            .then(() => {
                setJobState({type: 'NO_JOB'});
            }).catch(() => {
            addToast({
                type: 'error',
                body: Translation.messages().common.error.something_went_wrong,
                autoDismiss: false,
            });
        });
    };

    function sortBookings(): Booking[] {
        const sorted: Booking[] = [...filteredBookings];
        sorted.sort((a, b) => {
            return moment(a.createdAt).diff(b.createdAt);
        });
        return isSortAscending ? sorted : sorted.reverse();
    }

    const renderTable = () => {
        if (isLoading) {
            return <Spinner size="md" />;
        } else if (bookings.length > 0) {
            return (
                <>
                    <div className="bookings-table-searchbar">
                        <SearchBar
                            allItems={bookings}
                            updateItems={(items) => {
                                const count = Math.ceil(items.length / maxBookingPerPage);
                                setTotalPageCount(count);
                                setFilteredBookings(items);
                                setCurrentPage(1);
                            }}
                            doesItemMatchWord={getSearchFilter}
                            searchMultiFilters={getFilters()}
                            defaultSearchMultiFilters={getDefaultFilters()}
                            countSearchMultiFiltersItems={(count) => setBookingsStatusCount({notUsed: count.valid, inUse: count.inUse, used: count.used, expired: count.expired})}
                        />
                    </div>
                    {filteredBookings.length > 0 ?
                        (
                            <>
                                <BookingsTable
                                    clientId={activePermit.clientId}
                                    getBookings={getBookings}
                                    bookings={sortBookings().slice((currentPage - 1) * maxBookingPerPage, (currentPage) * maxBookingPerPage)}
                                    fetchAvailability={props.fetchAvailability}
                                    onEditClick={onEdit}
                                    isSortAscending={isSortAscending}
                                    setIsSortAscending={() => setIsSortAscending(!isSortAscending)}
                                />
                                <div className="pagination-container" style={{float: 'right'}}>
                                    <Spacer size="md" />
                                    <Pagination
                                        currentPage={currentPage}
                                        totalPages={totalPageCount}
                                        navigateBack={() => setCurrentPage(Math.max(currentPage - 1, 1))}
                                        navigateNext={() => setCurrentPage(Math.min(currentPage + 1, totalPageCount))}
                                        pageStatusString={`Page ${currentPage} of ${totalPageCount}`}
                                    />
                                    <Spacer size="lg" />
                                </div>
                            </>
                        ) :
                        <p>No results matched the search criteria within selected filters. Check unselected filters or try another keyword.</p>}
                </>
            );
        } else {
            return <p>There are no bookings yet.</p>;
        }
    };

    return (
        <>
            <div className="select-and-create-booking-container">
                <h1>Bookings</h1>
                <Spacer size="sm" />
                <Label>Select permit</Label>
                <Select
                    options={permitList}
                    className="select-permit"
                    onChange={onPermitChange}
                    value={getSelectedPermit()}
                />
                <Spacer size="sm" />
                <Button color="add" onClick={() => props.onCreateClick(activePermit, props.bookingsAvailability)}>Create booking</Button>
            </div>
            <Spacer size="sm" />
            {activePermit.accessLimit &&
                (
                    <>
                        <Availability
                            bookingsAvailability={props.bookingsAvailability}
                            showRefresh={true}
                            refreshAvailability={refresh}
                        />
                        <Spacer size="sm" />
                    </>
                )
            }
            <BookingJobProgressInformation jobState={jobState} removeJob={removeJob} cancelJob={cancelJob} />
            {renderTable()}
        </>
    );
};

export default BookingList;
