import {firebaseAuthService} from '@autopay.io/common/lib/auth';

export function getAuthorizationToken(): Promise<string> {
    if (firebaseAuthService.currentUser) {
        return firebaseAuthService.currentUser.getIdToken();
    } else {
        return Promise.reject('no token');
    }
}

/* Raven utils */

type RavenWarningLevels = 'warn' | 'info' | 'error';

/**
 * @param error an actual Error Object, not a string. If you want to pass a string, make sure you wrap it in new Error()
 * @param level
 */
export const logErrorToRaven = (error: any, level?: RavenWarningLevels) => {
    Raven.captureException((error),
        {level: level ? level : null},
    );
};
export const logMessageToRaven = (msg: string, level?: RavenWarningLevels) => {
    Raven.captureMessage((msg), {
        level: level ? level : null,
    });
};
