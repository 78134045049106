import * as React from 'react';
import {User} from '@autopay.io/common/lib/auth';
import {SidebarMenuProps} from '@autopay.io/style/lib/components/Menu/Menu';
import {Products} from './types/products';

interface UserType {
    type: 'USER';
    data: User;
}

interface LoadingType {
    type: 'LOADING';
}

interface NoUser {
    type: 'NO_USER';
}

export type AppContextUser = NoUser | UserType | LoadingType;

export interface AppContext {
    user: AppContextUser;
}

export const context = React.createContext<AppContext>({
    user: {type: 'NO_USER'},
});

export const AppContextProvider = context.Provider;

export const AppContextConsumer = context.Consumer;
