import {ApiMethods, HttpError, HttpGet, HttpPost, HttpPut, HttpResponse, RequestInitializer} from '../types/http';
import {getAuthorizationToken} from './utils';
import {BookingsAvailability, Permit, UpdateDurationsPayload, SingleBookingPayload, BookingOverlap, BookingsStatusCount, Booking} from '../scenes/bookings/types';
import {apiMap} from '@autopay.io/common/lib/util';

export function fetchBookings(
    permitDefinitionId: string,
    tenantId: string,
    operator: string,
    clientId: string,
): Promise<HttpResponse<Booking[]>> {
    const url = `${apiMap.bookingClient}/client/bookings?tenantId=${tenantId}&operator=${operator}&clientId=${clientId}&permitDefinitionId=${permitDefinitionId}`;
    return httpGet<Booking[]>({ url });
}

export function fetchPermits(): Promise<HttpResponse<Permit[]>> {
    return httpGet<Permit[]>({url: `${apiMap.bookingClient}/client/permit_definitions`});
}

export function getAvailability(clientId: string, validFrom: string): Promise<HttpResponse<BookingsAvailability>> {
    return httpGet<BookingsAvailability>({url: `${apiMap.bookingClient}/client/availability?clientId=${clientId}&validFrom=${encodeURIComponent(validFrom)}`});
}

export function checkOverlap(form: SingleBookingPayload): Promise<HttpResponse<BookingOverlap>> {
    const url = `${apiMap.bookingClient}/client/bookings/overlap`;
    return httpPost<BookingOverlap>({ url, body: form });
}

export function createBooking(form: SingleBookingPayload): Promise<HttpResponse<void>> {
    const url = `${apiMap.bookingClient}/client/booking`;

    return httpPost<void>({url, body: form});
}

export function editBooking(form: SingleBookingPayload): Promise<HttpResponse<void>> {
    const url = `${apiMap.bookingClient}/client/booking/${form.bookingId}`;

    return httpPut<void>({url, body: form});
}
export function deleteBooking(bookingId: string, clientId: string): Promise<HttpResponse<void>> {
    const url = `${apiMap.bookingClient}/client/booking?bookingId=${bookingId}&clientId=${clientId}`;

    return httpDelete<void>({url});
}

export function updateDurations(form: UpdateDurationsPayload): Promise<HttpResponse<void>> {
    const url = `${apiMap.bookingClient}/client/durations`;

    return httpPost<void>({url, body: form});
}

export function httpPost<T>({url, body}: HttpPost): Promise<HttpResponse<T>> {
    return http(url, 'POST', createBody(body));
}

export function httpPut<T>({url, body}: HttpPut): Promise<HttpResponse<T>> {
    return http(url, 'PUT', createBody(body));
}

export function httpGet<T>({url}: HttpGet): Promise<HttpResponse<T>> {
    return http(url, 'GET');
}

export function httpDelete<T>({url}: HttpGet): Promise<HttpResponse<T>> {
    return http(url, 'DELETE');
}

function http<T>(url: string, method: ApiMethods, body?: any): Promise<HttpResponse<T>> {
    return getAuthorizationToken().then((token: string) => {
        return fetch(
            url, createInit({token, body, method}),
        ).then(async (response: Response): Promise<HttpResponse<T>> => {
            const contentType = response.headers.get('content-type');

            if (contentType !== null && contentType.indexOf('application/json') !== -1 && response.status >= 200 && response.status <= 300) {
                const parsedJson = await response.json();

                return {data: parsedJson, type: 'SUCCESS'};
            } else if (response.ok) {
                return { type: 'OK' };
            } else if (contentType !== null && contentType.indexOf('application/json') !== -1 && response.status === 400) {
                const json = await response.json();
                return { type: 'ERROR_MESSAGE', message: json.message };
            } else {
                return Promise.reject();
            }
        });
    }).catch((): HttpError => {
        return ({type: 'ERROR'});
    });
}

function createBody<T>(body: T): string {
    return JSON.stringify(body);
}

function createInit(requestInit: RequestInitializer): RequestInit {
    const init: RequestInit = {
        headers: {
            Authorization: 'Bearer ' + requestInit.token,
            ...requestInit.body && {'content-type': 'application/json'},
        },
        method: requestInit.method,
        body: requestInit.body ? requestInit.body : null,
    };
    return init;
}
