import * as React from 'react';
import {Redirect, Route} from 'react-router-dom';
import {Switch} from 'react-router';
import {initializeToasts, ToastContextProvider, ToastElements} from '@autopay.io/style';
import {routes} from './routes';
import {Login} from './scenes/unauthenticatedUser/login';
import PrivateRoute from './components/privateRoute';
import {context} from './context';
import {ForgotPassword} from './scenes/unauthenticatedUser/forgotPassword';
import {SetPassword} from './scenes/unauthenticatedUser/setPassword';
import {Bookings} from './scenes/bookings';

const Router = () => {
    const {toasts, removeToast, addNewToast} = initializeToasts();
    const {user} = React.useContext(context);

    const renderRoot = () => {
        if (user.type === 'LOADING') {
            return null;
        }
        if (user.type === 'USER') {
            return <Redirect to={routes.BOOKINGS} />;
        }

        return <Redirect to={routes.LOGIN} />;
    };

    return (
        <ToastContextProvider value={addNewToast}>
            <Switch>
                <PrivateRoute path={routes.BOOKINGS} Component={Bookings} />
                <Route path={routes.SET_PASSWORD} component={SetPassword} />
                <Route path={routes.RESET_PASSWORD} component={ForgotPassword} />
                <Route path={routes.LOGIN} component={Login} />
                <Route path={routes.ROOT} render={renderRoot} />
            </Switch>
            <ToastElements toasts={toasts} removeToast={removeToast} />
        </ToastContextProvider>
    );
};

export default Router;
